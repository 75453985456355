import { Container } from '@mui/material';
import React from 'react'
import investmentImage from '../assets/Images/What we do/Homepage-Investment-scaled.webp'
import groupInvestment from '../assets/Images/What we do/Investment-Advisory-Board-500x344.webp'
import investmentAnimation from '../assets/Images/What we do/investment-process-final.png'
import wealthReportingImage from '../assets/Images/What we do/Wealth-Reporting-768x533.webp'
// import wealthStrategyImage from '../assets/Images/What we do/Wealth-Strategy-1-768x516.webp'
import governance from '../assets/Images/What we do/Governance.webp'
import taxAndPractice from '../assets/Images/What we do/Tax-and-Accounting-Practice-768x522.webp'
import lifeGoals from '../assets/Images/What we do/Life-Goals-768x518.webp'
import ImageLoader from '../components/Loader/ImageLoader';

// images
import publicMarketImage from '../assets/Images/What we do/public-market.webp'
import privateMarketImage from '../assets/Images/What we do/private-market.webp'
import globalMarketImage from '../assets/Images/What we do/global-market.webp'
import privateCreditImage from '../assets/Images/What we do/private-credit.webp'
import realEstateImage from '../assets/Images/What we do/real-estate.webp'
import Gap from '../components/Gap';


const data = [
    {
        id: 1,
        img: publicMarketImage,
        title: 'Public Markets ',
        description: 'Our public market investments encompass a wide range of instruments, including Exchange Traded Funds (ETFs), Mutual Funds, Portfolio Management Services (PMS), Hedge Funds, and Alternative Investment Funds (AIFs) in addition to direct equties. We carefully select these investments to target attractive risk-adjusted returns across various market cycles. Our public market strategy is designed to provide a balance of growth and stability to our overall portfolio. To generate stable returns and provide downside protection, we invest in a diverse range of debt and fixed income instruments. These include Debt Mutual Funds, Non-Convertible Debentures (NCDs), Bonds, Long-Short Funds, Structured and Venture Debt, and Infrastructure Investment Trusts (InvITs). Our debt and fixed income investments are carefully chosen to optimize risk-adjusted returns while maintaining a strong focus on capital preservation. ',
        alt: 'wealth strategy image richmount ventures'
    },
    {
        id: 10,
        img: privateMarketImage,
        title: 'Private Markets ',
        description: 'In private markets, we invest in unlisted equities, taking direct stakes in early, mid, and late-stage businesses with strong growth potential. We also allocate funds to funds managed by experienced managers with proven track records. By investing in private markets, we aim to capture long-term growth opportunities and generate superior returns.'
    },
    {
        id: 12,
        img: globalMarketImage,
        title: 'Global Markets  ',
        description: 'To further diversify our portfolio and tap into global growth opportunities, we invest in offshore assets across key markets such as the UK, USA, and Singapore. We work with dedicated wealth advisors in each of these regions to identify and evaluate unique investment opportunities, including emerging market plays and special situation investments. '
    },
    {
        id: 18,
        img: privateCreditImage,
        title: 'Private Credit   ',
        description: 'We engage in private credit investments, such as direct lending to middle-market companies, mezzanine financing, distressed debt, and specialty finance. These investments offer higher yields and diversification benefits compared to traditional fixed-income investments. We work with experienced managers and conduct thorough due diligence to identify attractive private credit opportunities that align with our investment objectives.'
    },
    {
        id: 120,
        img: realEstateImage,
        title: 'Real Estate    ',
        description: 'Real estate forms a crucial component of our investment strategy, as we believe in the long-term value creation potential of this asset class. Our real estate allocation includes investments in commercial and residential projects, Real Estate Investment Trusts (REITs), and strategic land banks. We target attractive development opportunities in high-growth locations, focusing on projects with strong fundamentals and experienced management teams.'
    },
    {
        id: 2,
        img: wealthReportingImage,
        title: 'Wealth reporting',
        description: "We prepare robust reporting frameworks that give a bird's eye view of the balanced, risk-adjusted investment portfolio. This aids in taking data-driven investment decisions keeping in mind the family-office's long-term objectives. ",
        alt: 'wealth report image richmount ventures'
    },

    {
        id: 3,
        img: groupInvestment,
        title: 'Investment advisory team',
        description: "Our dedicated investment advisory team, comprising professionals and subject matter experts, provides guidance and support to ensure the success of our family office's investment strategy. ",
        alt: 'group investment image richmount ventures'
    },
    {
        id: 4,
        img: governance,
        title: 'Governance',
        description: "We have strong policies in place that ensure strict adherence to the agreed investment principles, ensuring transparency between the family-office's principals and the operating team. ",
        alt: 'governance image richmount ventures'
    },
    {
        id: 5,
        img: taxAndPractice,
        title: 'Tax and accounting practices',
        description: 'We establish a strong tax and accounting framework that evaluates multiple post-tax scenarios for each asset class within the portfolio and recommends the most cost-effective options.',
        alt: 'tax and price image richmount ventures'
    },
    {
        id: 6,
        img: lifeGoals,
        title: 'Life goals',
        description: 'We have clearly articulated and well-defined policies to fulfill personal wealth-utilization aspirations and dreams. Our investment strategies prioritize such personal goals, ensuring they receive appropriate consideration and attention. ',
        alt: 'life goals image richmount ventures'
    },
    {
        id: 7,
        img: investmentAnimation,
        title: 'Investment Process ',
        description: 'Our investment process includes independent wealth analysis, implication analysis, risk overview, cash flow budgeting, asset consolidation, performance analysis, and management cost analysis. This comprehensive approach ensures that we make informed decisions based on objective analysis, while considering tax implications, risk assessment, liquidity management, and regular portfolio valuation. ',
        alt: 'investment process',
        contain: true
    }
]

// const investmentProcess = [
//     {
//         id: 1,
//         title: 'Independent Wealth Analysis',
//         description: 'Make decisions and fine tune on the basis of independent and objective analysis.'
//     },
//     {
//         id: 2,
//         title: 'Implication Analysis',
//         description: 'Ensure all tax implications and any other implication on reputation, ownership structure, nominations, etc. are adequately addressed before investing.'
//     }
// ]

// const investmentProcess2 = [
//     {
//         id: 3,
//         title: 'Risk Overview',
//         description: 'Robust risk assessment and asset protection frameworks to protect any downside.',
//         description2: 'Complete risk assessment for evaluating and verifying the quality of investments.'
//     },
//     {
//         id: 4,
//         title: 'Asset Consolidation',
//         description: "Have a bird's eye view of the family's assets with personalized reporting and dashboards.",
//         description2: 'Regular valuation of the entire portfolio across accounts held with numerous financial intermediaries.'
//     }
// ]

const WhatWeDo = () => {
    return (
        <Container>
          <Gap />
            <ImageLoader src={investmentImage} alt="Investment richmount ventures" />
            <p className='mt-4 text-justify font-normal my-4 '>
                {/* The family office focuses on identifying investment opportunities and explore new investment models, research emerging trends and markets, and identifying new opportunities to create a diversified portfolio that is rooted in sound investment principles and aligned with our long-term objectives. */}

                At Richmount Ventures, our primary focus is to identify and capitalize on investment opportunities across various asset classes to create a diversified portfolio that aligns with our long-term objectives and investment principles. Our investment strategy is designed to maximize returns while managing risk, ensuring the preservation and growth of our family's wealth for generations to come.
            </p>
            <Container className='mx-10 mt-14 lg:mb-10  '>
                {
                    data.map((item) => {
                        return (
                            <>
                                <div key={item.id} className='flex items-center sm:max-md:flex-wrap sm:max-sm:flex-wrap gap-16 my-14 custom-what-we-do-container'>
                                    <img className={`${item?.contain ? 'object-contain' : 'object-cover'}  md:w-[280px] md:h-[200px] w-full h-[300px] `} src={item.img} alt={item.alt} />
                                    <div>
                                        <h3 className='text-2xl text-customBlue1 font-medium mb-3 '>{item.title}</h3>
                                        <p className='text-justify font-normal'>{item.description}</p>
                                    </div>
                                </div >
                            </>
                        )
                    })
                }
                <p className=' text-justify font-normal '>At Richmount Ventures, we believe that a well-diversified investment portfolio, spanning multiple asset classes and geographies, is essential for long-term wealth creation and preservation. Our investment philosophy is rooted in thorough research, risk management, and a commitment to generating sustainable, risk-adjusted returns over the long term.</p>
            </Container>


            {/* <div className=''>
                <img className='object-contain width-fit my-10 mx-auto' src={investmentAnimation} alt="Investment Animation richmount ventures" />
                <h2 className='text-2xl font-medium text-customBlue1 text-center my-10'>Investment Process</h2>
                <div>
                    <div className='inline-flex sm:max-md:flex-col sm:max-sm:flex-col custom-what-we-do-container gap-10'>
                        {investmentProcess.map((item) => (

                            <div key={item.id} className='w-full md:w-1/2 mx-3 mb-8'>
                                <h3 className='text-xl text-center font-medium text-customBlue1'>{item.title}</h3>
                                <p className='mt-4 text-justify font-normal my-4'>{item.description}</p>
                                {item?.description2 && (
                                    <p className='mt-4 text-justify font-normal my-4'>{item.description2}</p>
                                )}
                            </div>

                        ))}
                    </div>
                    <div className='inline-flex sm:max-md:flex-col sm:max-sm:flex-col custom-what-we-do-container gap-10'>
                        {investmentProcess2.map((item) => (
                            <div key={item.id} className='w-full md:w-1/2 mx-3 mb-8'>
                                <h3 className='text-xl text-center font-medium text-customBlue1'>{item.title}</h3>
                                <p className='mt-4 text-justify font-normal my-4'>{item.description}</p>
                                {item.description2 && (
                                    <p className='mt-4 text-justify font-normal my-4'>{item.description2}</p>
                                )}
                            </div>
                        ))}
                    </div>
                </div>
                <div className='inline-flex sm:max-md:flex sm:max-sm:flex-col custom-what-we-do-container gap-10 my-10'>
                    <div className='w-full md:w-1/2 mx-3 mb-8'>
                        <h3 className='text-xl text-center font-medium text-customBlue1'>Cash flow budgeting</h3>
                        <p className='mt-4 text-justify font-normal my-4'>Cash flow projections and management of the same.</p>
                    </div>
                    <div className='w-full md:w-1/2 mx-3 mb-8'>
                        <h3 className='text-xl text-center font-medium text-customBlue1'>Performance Analysis</h3>
                        <p className='mt-4 text-justify font-normal my-4'>Detailed performance report by aggregating various clusters (analysis by asset class, security, business sector, country etc.). </p>
                    </div>
                    <div className='w-full md:w-1/2 mx-3 mb-8'>
                        <h3 className='text-xl text-center font-medium text-customBlue1'>Management Cost Analysis</h3>
                        <p className='mt-4 text-justify font-normal my-4'>Independently analyze banking fees and taxes on assets to have a full and accurate picture of the investments portfolio.</p>
                    </div>
                </div>
               
            </div> */}
        </Container >
    )
}

export default WhatWeDo;


// Independent wealth analysis
// Make decisions and fine tune on the basis of independent and objective analysis.
//
// Implication analysis
// Ensure all tax implications and any other implication on reputation, ownership structure, nominations, etc. are
// adequately addressed before investing.
//
// Risk overview
// Robust risk assessment and asset protection frameworks to protect any downside.
// Complete risk assessment for evaluating and verifying the quality of investments.
//
// Cash flow budgeting
// Cash flow projections and management to ensure appropriate liquidity management to be able to invest in
// opportunistic growth opportunities as well.
// Asset consolidation
// Have a bird&#39;s eye view of assets with personalized reporting and dashboards. Also conduct regular valuation of the
// portfolio across accounts held with numerous financial intermediaries.
// Performance analysis
// Detailed performance report by aggregating various data clusters (analysis by asset class, security, business sector,
// country etc.).
//
// Management cost analysis
// Independently analyze banking fees and taxes on assets to have a full and accurate picture of the investments
// portfolio.