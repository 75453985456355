import React from 'react';
import { NavLink } from 'react-router-dom';

const NotFoundPage = () => {
  return (
    <div className="flex flex-col items-center justify-center h-screen bg-gray-100">
      <h1 className="text-4xl font-bold text-gray-800 mb-6">Not Found</h1>
      <p className="text-lg text-gray-600 mb-8">The page you are looking for does not exist.</p>
      <NavLink
        to="/"
        className="px-4 py-2 bg-white shadow-lg border-2 border-customBlue1 text-customBlue1 rounded-md font-medium hover:bg-blue-600 transition duration-300 text-white"
      >
        Go To Home
      </NavLink>
    </div>
  );
};

export default NotFoundPage;
