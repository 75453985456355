import { Container } from '@mui/material'
import React from 'react'
import phoneGuy from '../assets/Images/About As/close-up-hand-holding-smartphone-scaled-1.webp'
import bookImg from '../assets/Images/About As/About-us-Our-Story-scaled-e1676461687590.webp'
import groupHands from '../assets/Images/About As/About-us-Our-Values.webp'
import ImageLoader from '../components/Loader/ImageLoader'
import Gap from '../components/Gap';

const About = () => {
    return (
        <Container>
         <Gap />
            <ImageLoader src={phoneGuy} alt="Phone with guy richmount ventures" />
            <div className='my-6'>
                <h2 className='text-3xl mt-4 text-center text-customBlue1 font-medium sm:text-4xl'>What is family office</h2>
                <p className='mt-4 text-justify font-normal my-4 '>
                    A family office is a structure through which the money and wealth of a family, in a consolidated form, are invested and tracked in a professional manner.
                </p>
                <p className='mt-4 text-justify font-normal my-4 '>
                    This structure provides a comprehensive overview of the family's investments and allows them to track their portfolio's performance against pre-set return objectives.
                </p>
                <p className='mt-4 text-justify font-normal my-4 '>
                    Additionally, it incorporates a process to periodically review the risk-return profile of the portfolio and rebalance it, if necessary, to ensure alignment with the family's investment objectives.
                </p>
                <p className='mt-4 text-justify font-normal my-4 '>
                    While setting up a family office, it is crucial to recognize that family members may have their own business. To maintain the integrity of the family office structure, it is necessary to ensure that these activities are legally and structurally separate from the family business.
                </p>
                <p className='mt-4 text-justify font-normal my-4 '>
                    This ensures that the family's wealth is managed independently and professionally, with no conflicts of interest or potential for mixing personal and business finances. By establishing a family office, families can achieve greater transparency, control, and efficiency in managing their wealth, which ultimately leads to sustainable long-term growth and success.
                </p>
            </div>
            <div className='my-6'>
                <ImageLoader src={bookImg} alt="Book opening richmount ventures" />
                <h2 className='text-3xl mt-4 text-center text-customBlue1 font-medium sm:text-4xl'>Our Story</h2>
                <p className='mt-4 text-justify font-normal my-4 '>
                    Richmount Ventures is a single-family office that was established in 2018 by Kartekeya Myadam, its founder and Chief Investment Officer. The office's primary objective is to manage the family's wealth in a comprehensive manner, while also laying out a roadmap for future generations. To achieve this, Richmount Ventures works with a dedicated investment advisory team and globally renowned wealth managers to identify and evaluate investment opportunities in public markets, private markets, global markets and real estate.
                </p>
                <p className='mt-4 text-justify font-normal my-4 '>
                    Our investment portfolio comprises a diverse range of asset classes, carefully selected to maximize returns while managing risk. In private markets, we invest in unlisted equities, take direct stakes in promising startups, and allocate to tech and consumer funds managed by experienced managers. We also engage in private credit investments, such as direct lending to middle-market companies, mezzanine financing, distressed debt, and specialty finance, which offer higher yields and diversification benefits. Our public market investments include ETFs, Mutual Funds, PMS, Hedge Funds, and AIFs, which are chosen to deliver attractive risk-adjusted returns. To provide stable income and downside protection, we invest in debt and fixed income instruments such as Debt Mutual Funds, NCDs, Bonds, Long-Short Funds, Structured and Venture Debt, and InvITs. Real estate also forms a crucial part of our portfolio, with investments in commercial and residential projects, REITs, and strategic land banks in high-growth locations. By diversifying across these asset classes, including private credit, we aim to create a well-balanced and resilient portfolio that can weather market fluctuations and generate sustainable long-term growth.
                </p>
                <p className='mt-4 text-justify font-normal my-4 '>
                    Under Kartekeya's leadership, Richmount Ventures has established well defined structures backed by strong investment policy guidelines, strong governance frameworks and has adapted global standard systems and technologies. The office's core objective is to create a well-diversified portfolio that focuses on strategic investments that create long-term value.
                </p>
                <div className='py-5'>
                    <p className='mt-4 text-customBlue1 text-4xl text-justify font-normal my-4 font-cursive ' style={{ textAlign: 'center' }}>
                        “ I do not look to jump over seven foot bars; I look around for one foot bar that I can step over.”
                    </p>
                    <p className='mt-4 text-customBlue1 text-4xl text-justify font-normal my-4 font-cursive ' style={{ textAlign: 'center' }}>
                        - Warren Buffet.
                    </p>
                </div>
            </div>
            <div className='my-10 '>
                <h2 className='text-3xl mt-4 mb-4 text-center text-customBlue1 font-medium sm:text-4xl'>Our Purpose</h2>
                <p className='mt-4 text-justify font-normal my-4 '>
                    Our family office aims to establish a resilient structure rooted in long-term strategic and value investing principles, promoting transparency and equity to create wealth for future generations.         </p>
                <p className='mt-4 text-justify font-normal my-4 '>
                    Our focus is on developing robust investments and governance capabilities, and acquiring expertise in sustainable onshore and offshore investments across asset classes. We are committed to incorporating sustainability and social consciousness into all our investments, with the aim of leaving a positive legacy for generations to come.
                </p>
            </div>
            <div className='my-6'>
                <ImageLoader src={groupHands} alt="group of hands richmount ventures" />
                <h2 className='text-3xl mt-4 text-center text-customBlue1 font-medium sm:text-4xl'>Our Values</h2>
                <p className='mt-4 text-justify font-normal my-4 '>
                    <span className='font-medium'> Integrity: </span> We conduct ourselves with honesty, transparency, and the highest ethical standards.
                </p>
                <p className='mt-4 text-justify font-normal my-4 '>
                    <span className='font-medium'> Excellence: </span> We are committed to achieving excellence and ensuring that every aspect of our work meets the highest standards of quality.
                </p>
                <p className='mt-4 text-justify font-normal my-4 '>
                    <span className='font-medium'> Collaboration: </span> We believe in working together, respecting diverse perspectives and contributions to achieve common goals.
                </p>
                <p className='mt-4 text-justify font-normal my-4 '>
                    <span className='font-medium'> Innovation: </span> We embrace new ideas, technologies and creative solutions that help us improve and grow.
                </p>
                <p className='mt-4 text-justify font-normal my-4 '>
                    <span className='font-medium'> Stewardship: </span> We are committed to responsible stewardship of our resources, including financial, social, and environmental, for the benefit of future generations.
                </p>
                <p className='mt-4 text-justify font-normal my-4 '>
                    <span className='font-medium'> People first: </span> Putting people first is a value that prioritizes the well - being, dignity, and empowerment of individuals, whether they are employees, customers, or members of the community.
                </p>
            </div>

        </Container>
    )
}

export default About;
