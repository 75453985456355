import axios from 'axios';

const API_URL = 'https://richmount.co/v1/api/visit';

export const recordVisit = async () => {
  try {
    await axios.post(`${API_URL}/record`);
  } catch (error) {
    console.error('Error recording visit:', error);
  }
};

export const getTotalVisits = async () => {
  try {
    const response = await axios.get(`${API_URL}/total`);
    return response.data;
  } catch (error) {
    console.error('Error fetching total visits:', error);
  }
};

export const getTodaysVisits = async () => {
  try {
    const response = await axios.get(`${API_URL}/today`);
    return response.data;
  } catch (error) {
    console.error('Error fetching today\'s visits:', error);
  }
};

export const getUniqueVisitors = async () => {
  try {
    const response = await axios.get(`${API_URL}/unique`);
    return response.data;
  } catch (error) {
    console.error('Error fetching unique visitors:', error);
  }
};
