import React from "react";
import ImageLoader from "./Loader/ImageLoader";
import { Container } from "@mui/material";

const ImageWithContent = (props) => {
  return (
    <Container>
      <div className="flex-col items-center justify-center my-5">
        <ImageLoader src={props.img} alt="kartekeya" />
        <h3 className="text-3xl font-medium text-center text-customBlue1 ">
          {props.header}
        </h3>
        {props.subHeader && (
          <h4 className="text-lg text-customBlue1 font-normal text-center my-4 ">
            {props.subHeader}
          </h4>
        )}
        <p className="mt-4 text-justify font-normal my-4 ">{props.para1}</p>
        {props.anchor && (
          <a
            className="mt-4 text-justify font-normal my-4 text-customBlue1"
            href={props.anchorLink}
          >
            {props.anchorTitle}
          </a>
        )}
      </div>
    </Container>
  );
};

export default ImageWithContent;
