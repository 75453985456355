import { Routes, Route, BrowserRouter } from "react-router-dom";
import RootLayout from "./layouts/RootLayout";
import Home from "./pages/Home";
import About from "./pages/About";
import WhatWeDo from "./pages/WhatWeDo";
import Legacy from "./pages/Legacy";
import NotFoundPage from "./pages/NotFoundPage";
import Leadership from "./pages/Leadership";
import Philanthropy from "./pages/Philonthropy";
import ContactUs from "./pages/ContactUs";
import Media from "./pages/Media";



function App() {

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<RootLayout />}>
          <Route index element={<Home />} />
          <Route path="about" element={<About />} />
          <Route path="leadership"  element={<Leadership />}/>
          <Route path="what-we-do" element={<WhatWeDo />} />
          <Route path="legacy" element={<Legacy />} />
          <Route path="philanthropy" element={<Philanthropy />} />
          <Route path="media" element={<Media />} />
          <Route path="contact-us" element={<ContactUs />} />
          <Route path="*" element={<NotFoundPage />} />
        </Route>
      </Routes> 
    </BrowserRouter>
  );
}

export default App;
