import React from "react";
import SimpleSlider from "./SimpleSlider";
// import hero from "../assets/Images/HomePage/Richmount-hero-image.webp";
import ImageLoader from "./Loader/ImageLoader";

const SliderWithContent = (props) => {
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    autoplay: true,
    speed: 500,
    autoplaySpeed: 1500,
    cssEase: "linear",
  };

  return (
    <div className="flex flex-col gap-5 mb-14 mx-14">
      <SimpleSlider {...settings} className=" ">
        <div>
          <ImageLoader
            style={{ width: "100%", height: "550px" }}
            src={props.slider.img1}
          />
        </div>
        {/* <div>
          <ImageLoader
            style={{ width: "100%", height: "550px" }}
            src={props.slider.img2}
          />
        </div> */}
        {props.slider.img2 && (
          <div>
            <ImageLoader
              style={{ width: "100%", height: "550px" }}
              src={props.slider.img2}
            />
          </div>
        )}
        {props.slider.img3 && (
          <div>
            <ImageLoader
              style={{ width: "100%", height: "550px" }}
              src={props.slider.img3}
            />
          </div>
        )}
        {props.slider.img4 && (
          <div>
            <ImageLoader
              style={{ width: "100%", height: "550px" }}
              src={props.slider.img4}
            />
          </div>
        )}
      </SimpleSlider>

      <div className="flex-col items-center justify-center my-5">
        <h3 className="text-3xl font-medium text-center text-customBlue1 ">
          {props.header}
        </h3>
        <h4 className="text-2xl font-normal text-center my-6">
          {props.subHeader}
        </h4>
        <p className="mt-4 text-justify font-normal my-4 ">{props.para1}</p>
        {props.anchor && (
          <a
            target="blank"
            className="mt-4 text-justify font-normal my-4 text-customBlue1"
            href={props.anchorLink}
          >
            {props.anchorTitle}
          </a>
        )}
        <p className="mt-4 text-justify font-normal my-4 ">{props.para2}</p>
        <p className="mt-4 text-justify font-normal my-4 ">{props.para3}</p>
      </div>
    </div>
  );
};

export default SliderWithContent;
