import React, { useState } from 'react';
import Loader from './Loader';

const ImageLoader = ({ src, alt , banner }) => {
  const [loading, setLoading] = useState(true);

  const handleImageLoaded = () => {
    setLoading(false);
  };

  return (
    <div className='flex items-center justify-center w-full h-full'>
      {loading && <Loader />}
      <img
        src={src}
        alt={alt}
        style={{ display: loading ? 'none' : 'block' }}
        className={`md:object-cover object-contain w-full my-4 
                 h-full  // Height for large and medium screens
                 ${banner ? 'md:h-[680px]' : 'md:h-[480px]'} // Ensures medium screens match large
                 max-w-full`} // Maintain responsive width
        onLoad={handleImageLoaded}

      />
    </div>
  );
};

export default ImageLoader;
