import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import {
    AppBar,
    Toolbar,
    IconButton,
    Drawer,
    Box,
    useMediaQuery,
    useTheme,
    Container,
    styled,
} from '@mui/material';
import { Close, Menu as MenuIcon } from '@mui/icons-material';
import RichmountLogo from '../../assets/Images/Richmount-logo-final-crop.png';

const drawerWidth = 240;

const StyledNavLink = styled(NavLink)({
    textDecoration: 'none',
    color: '#105bab',
    background: '#fff',
    fontSize: 15,
})

const Navbar = () => {
    const [showNavbar, setShowNavbar] = useState(false);
    const theme = useTheme();
    const isMobileOrTablet = useMediaQuery(theme.breakpoints.down('md'));

    const handleShowNavbar = () => {
        setShowNavbar(!showNavbar);
    };

    const closeNavbar = () => {
        setShowNavbar(false);
    };

    return (
        <AppBar position="fixed" sx={{ background: '#fff', color: '#105bab', top: 0, marginTop: 0, paddingY: 1, }} elevation={0}>
            <Container>
                <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div className="logo">
                        <NavLink to='/' >
                            <img width={'220px'} height={'180px'} src={RichmountLogo} alt="richmount" style={{ maxWidth: '100%', objectFit: 'cover', objectPosition: "50% 50%" }} />
                        </NavLink>
                    </div>
                    {isMobileOrTablet ? (
                        <>
                            <IconButton edge="start" color="inherit" aria-label="menu" onClick={handleShowNavbar}>
                                <MenuIcon />
                            </IconButton>
                            <Drawer
                                sx={{
                                    width: drawerWidth,
                                    flexShrink: 0,
                                    '& .MuiDrawer-paper': {
                                        width: drawerWidth,
                                        boxSizing: 'border-box',
                                    },
                                }}
                                anchor="left" open={showNavbar} onClose={handleShowNavbar}
                            >
                                <Box sx={{ display: 'flex', justifyContent: 'flex-end', p: 1 }}>
                                    <IconButton onClick={handleShowNavbar} aria-label="close">
                                        <Close />
                                    </IconButton>
                                </Box>
                                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4, padding: 5 }}>
                                    <StyledNavLink to='/about' onClick={closeNavbar}>About Us</StyledNavLink>
                                    <StyledNavLink to="/what-we-do" onClick={closeNavbar} >What We Do</StyledNavLink>
                                    <StyledNavLink to="/leadership" onClick={closeNavbar} >Leadership</StyledNavLink>
                                    <StyledNavLink to="/legacy" onClick={closeNavbar} >Legacy</StyledNavLink>
                                    <StyledNavLink to="/philanthropy" onClick={closeNavbar} >Philanthropy</StyledNavLink>
                                    <StyledNavLink to="/media" onClick={closeNavbar} >Media</StyledNavLink>
                                    <StyledNavLink to="/contact-us" onClick={closeNavbar} >Contact Us</StyledNavLink>
                                </Box>
                            </Drawer>
                        </>
                    ) : (
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 3 }}>
                            <StyledNavLink to='/about' onClick={closeNavbar}>About Us</StyledNavLink>
                            <StyledNavLink to="/what-we-do" onClick={closeNavbar} >What We Do</StyledNavLink>
                            <StyledNavLink to="/leadership" onClick={closeNavbar} >Leadership</StyledNavLink>
                            <StyledNavLink to="/legacy" onClick={closeNavbar} >Legacy</StyledNavLink>
                            <StyledNavLink to="/philanthropy" onClick={closeNavbar} >Philanthropy</StyledNavLink>
                            <StyledNavLink to="/media" onClick={closeNavbar} >Media</StyledNavLink>
                            <StyledNavLink to="/contact-us" onClick={closeNavbar} >Contact Us</StyledNavLink>
                        </Box>
                    )}
                </Toolbar>
            </Container>
        </AppBar>
    );
};

export default Navbar;
