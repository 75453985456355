import React from 'react';
import RichmountFooter from '../../assets/Images/richmount-new-logo-png.png';
import { Container } from '@mui/material';
import { NavLink } from 'react-router-dom';

const Footer = ({ totalVisits, todayVisit, uniqueVisit }) => {
  console.log('uniqueVisit: ', uniqueVisit);
  console.log('todayVisit: ', todayVisit);
  console.log('totalVisits: ', totalVisits);
  return (
    <>
      <Container>
        <div className='flex justify-around items-start sm:max-md:flex-wrap sm:max-sm:flex-wrap gap-14 my-10 custom-what-we-do-container p-6 my-4 border-y-2 border-slate'>
          <NavLink to='/'>
            <img className='object-contain w-72  sm:max-md:w-full' src={RichmountFooter} alt="Richmount Ventures" />
          </NavLink>
          <div className=''>
            <h3 className='text-xl font-medium'>CONTACT INFORMATION</h3>
            <div className='mt-6'>
              <h5 className='font-medium'>Richmount Ventures</h5>
              <p className=' text-sm'>
                Jyothi Prime, 4th Floor, <br />Office No. 1 & 2 Road No. 1,
                <br />Banjara Hills,
                Hyderabad- 500082
                <br />Telangana, India
              </p>
              <div className='mt-8 '>
                <div className='-my-1 '>
                  <span className='text-sm font-medium'>Phone : </span>
                  <span className='text-sm'>040 40664301 | 040 40664306</span>
                </div>
                <div className='-my-1'>
                  <span className='text-sm font-medium'>Email : </span>
                  <span className='text-sm'>richmountventures@richmount.co</span>
                </div>
                <div className='-my-1'>
                  <span className='text-sm font-medium'>Timings : </span>
                  <span className='text-sm'>Mon - Sat : 10.00 am - 6.30 pm</span>
                </div>
              </div>

            </div>
          </div>
        </div>
      </Container>
      <div></div>
      <Container className='my-4'>
        <p className='text-center font-thin text-xs my-2 text-gray-800'>Copyright © 2024 Richmount Ventures   </p>
        <p className='text-center font-light text-xs my-2 text-gray-800'>Powered by Richmount Ventures</p>
      </Container>

    </>
  );
};

export default Footer;
